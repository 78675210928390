import axios from "axios";
import API from "../constants/Api";

export default class Usuarios {
	/** 
	 * @type { string}
	 */
	nombres;
	/** 
	 * @type { string}
	 */
	apellidos;
	/** 
	 * @type { string}
	 */
	correo;
	/** 
	 * @type { string}
	 */
	telefono;
	/** 
	 * @type { boolean}
	 */
	activo;
	/** 
	 * @type { boolean}
	 */
	eliminado;
	/** 
	 * @type { import("./Tokens").default[]}
	 */
	tokens;
	/** 
	 * @type { import("./Suscripciones").default[]}
	 */
	suscripciones;
	/** 
	 * @type {boolean}
	 */
	isLogged
	/** 
	 * @type {string}
	 */
	authToken
	/**
	 * 
	 * @param {Usuarios} params 
	 */
	static ERRORS = {
		NOT_VALID_CREDENTIALS: "No valid credentials"
	}
	/**
	 * 
	 * @param {Usuarios} params 
	 */
	constructor(params) {
		Object.entries(params).forEach(([key, val]) => {
			this[key] = val
		})
	}
	/**
	 * 
	 * @param {string} usuario 
	 * @param {string} contrasenia 
	 * @returns {Promise<false|{accessToken: string}|null | string>}
	 */
	static async login(usuario, contrasenia) {
		try {
			usuario = usuario.replace(/\s/g, "");
			const axiosResponse = await axios.post(API.USUARIOS.login, {
				usuario, contrasenia
			}).catch(error => {
				return error.response
			});
			if (axiosResponse.status === 401) {
				return this.ERRORS.NOT_VALID_CREDENTIALS
			}
			if (!axiosResponse.status.toString().startsWith("20")) return false
			if (axiosResponse.status === 204) return null
			return axiosResponse.data.data
		} catch (error) {
			console.error("Error al hacer login: ", error)
			return false
		}
	}
	/**
	 * 
	 * @param {string} accessToken 
	 * 
	 * @returns {Promise<Usuarios| false>}
	 */
	static async info(accessToken) {
		try {
			const axiosResponse = await axios.get(API.USUARIOS.info, {
				headers: {
					"Authorization": accessToken
				}
			}).catch(error => {
				console.error(JSON.stringify(error, undefined, '\t'))
				return error.response
			});
			if (!axiosResponse.status.toString().startsWith("20")) {
				console.error(axiosResponse.data);
				return false
			}
			return new Usuarios(axiosResponse.data.data)
		} catch (error) {
			console.error("Error al obtener info de usuario: ", error)
			return false
		}
	}
}