import moment from "moment";
import API from "../constants/Api";

import axios from "axios";
import _ from "lodash";
export default class Estudiantes {
	/** 
		* @type { string}
	 */
	nombres;
	/** 
	 * @type { string}
	 */
	apellidos;
	/** 
	 * @type { string}
	 */
	correo;
	/** 
	 * @type { string}
	 */
	telefono;
	/** 
	 * @type { boolean}
	 */
	activo;
	/** 
	 * @type { boolean}
	 */
	eliminado;
	/** 
	 * @type { import('./RespuestasEstudiantes').default[]}
	 */
	respuestas;
	/** 
	 * @type { import('./Suscripciones').default[]}
	 */
	suscripciones;
	/** 
	 * @type { import('./Tokens').default[]}
	 */
	tokens;
	/** 
	 * @type {boolean}
	 */
	isLogged
	/** 
	 * @type {boolean}
	 */
	isPremium
	/** 
	 * @type {string}
	 */
	authToken
	/**
	 * 
	 * @param {Estudiantes} params 
	 */
	static ERRORS = {
		NOT_VALID_CREDENTIALS: "No valid credentials"
	}
	/**
	 * 
	 * @param {Estudiantes} params 
	 */
	constructor(params) {
		Object.entries(params).forEach(([key, val]) => {
			this[key] = val
		})
	}
	/**
	 * 
	 * @param {string} usuario 
	 * @param {string} contrasenia 
	 * @returns {Promise<false|{accessToken: string}|null>}
	 */
	static async login(usuario, contrasenia) {
		try {
			const axiosResponse = await axios.post(API.ESTUDIANTES.login, {
				usuario, contrasenia
			}).catch(error => {
				return error.response
			});
			if (axiosResponse.status === 401) {
				return this.ERRORS.NOT_VALID_CREDENTIALS
			}
			if (!axiosResponse.status.toString().startsWith("20")) return false
			if (axiosResponse.status === 204) return null
			return axiosResponse.data.data
		} catch (error) {
			console.error("Error al hacer login: ", error)
			return false
		}
	}
	/**
	 * @typedef {Estudiantes & {contrasenia: string}} RegisterParams
	 * @exports RegisterParams
	 */
	/** 
	 * @param {RegisterParams} params 
	 * @returns {Promise<false|Estudiantes|null>}
	 */
	static async register(params) {
		try {
			const axiosResponse = await axios.post(API.ESTUDIANTES.register, params).catch(error => {
				console.error(JSON.stringify(error, undefined, '\t'))
				return error.response
			});
			if (!axiosResponse.status.toString().startsWith("20")) {
				console.log(axiosResponse.data);
				return false
			}
			if (axiosResponse.status === 204) return null
			return axiosResponse.data.data
		} catch (error) {
			console.error("Error al hacer login: ", error)
			return false
		}
	}
	/**
	 * 
	 * @param {RegisterParams} params 
	 * @param {string} accessToken 
	 * @returns {Promise<false|Estudiantes|null>}
	 */
	static async update(params, accessToken) {
		try {
			const axiosResponse = await axios.post(API.ESTUDIANTES.update, params, {
				headers: {
					"Authorization": accessToken
				}
			}).catch(error => {
				console.error(JSON.stringify(error, undefined, '\t'))
				return error.response
			});
			if (!axiosResponse.status.toString().startsWith("20")) {
				console.error(axiosResponse.data);
				return false
			}
			return axiosResponse.data.data
		} catch (error) {
			console.error("Error al hacer update: ", error)
			return false
		}
	}
	/**
	 * 
	 * @param {string} accessToken 
	 * @returns {Promise<false|Estudiantes|null>}
	 */
	static async delete(accessToken) {
		try {
			const axiosResponse = await axios.post(API.ESTUDIANTES.delete, {}, {
				headers: {
					"Authorization": accessToken,
				}
			}).catch(error => {
				console.error(JSON.stringify(error, undefined, '\t'))
				return error.response
			});
			if (!axiosResponse.status.toString().startsWith("20")) {
				console.error(axiosResponse.data);
				return false
			}
			return axiosResponse.data.data
		} catch (error) {
			console.error("Error al hacer delete: ", error)
			return false
		}
	}
	/**
	 * 
	 * @param {string} accessToken 
	 * 
	 * @returns {Promise<Estudiantes| false>}
	 */
	static async info(accessToken) {
		try {
			const axiosResponse = await axios.get(API.ESTUDIANTES.info, {
				headers: {
					"Authorization": accessToken
				}
			}).catch(error => {
				console.error(JSON.stringify(error, undefined, '\t'))
				return error.response
			});
			if (!axiosResponse.status.toString().startsWith("20")) {
				console.error(axiosResponse.data);
				return false
			}
			return new Estudiantes(axiosResponse.data.data)
		} catch (error) {
			console.error("Error al obtener info de usuario: ", error)
			return false
		}
	}
	hasActiveSuscription() {
		try {
			if (_.isEmpty(this.suscripciones)) return false
			return this.suscripciones.some((sub) => {
				return moment(sub.fechaExpiracion).isAfter(moment(), "days");
			})
		} catch (error) {
			console.error("Error al verificar suscripciones", error);
		}
	}
}
