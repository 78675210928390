import React from 'react'

/**
 * 
 * @param {React.SVGProps<SVGSVGElement>} props 
 * @returns 
 */
export default function Logo(props) {
	return (

		<svg id="Capa_1" data-name="Capa 1" xmlns="http://www.w3.org/2000/svg" height={100} viewBox="0 0 38.74 59.44" {...props}>
			<path d="m16.8,37.15h6.15c.97.01,1.75.79,1.75,1.74,0,.95-.79,1.73-1.76,1.73h-.19s-.36.07-.36.07h-6.15c-.97,0-1.76-.76-1.76-1.71,0-.95.78-1.73,1.75-1.73h.09s.47-.09.47-.09Zm-.43-18.43h6.15c.24,0,.47.06.68.15l.04.03.27.05c.63.26,1.07.87,1.08,1.59,0,.95-.79,1.73-1.75,1.73h-6.15c-.24,0-.47-.04-.68-.13l-.08-.06-.24-.05c-.63-.26-1.07-.88-1.07-1.59,0-.95.79-1.72,1.76-1.72ZM19.02,1.72c2.62-.04,5.26.4,7.7,1.32,4.11,1.54,7.25,4.27,8.89,7.55l.1.22.03.04c.03.06.07.12.1.17.02.03.04.06.06.1.03.06.06.11.09.17.03.06.06.13.1.19.04.08.07.16.11.23.02.06.06.12.08.19.03.07.06.14.09.22.06.13.11.26.15.39.02.05.04.11.06.16.12.36.22.73.3,1.12.02.07.03.15.04.22.06.32.09.66.11.99,0,.08,0,.16,0,.24,0,.13,0,.25,0,.38-.06,2.46-1.18,4.73-3.03,6.58v-.13s-.13.15-.13.15l.14-6.46v-.27l-.09-1.08v-.05s-.45-1.62-.45-1.62c-1.19-3.17-4.14-5.85-8.16-7.24-3.96-1.36-8.5-1.31-12.4.16-3.35,1.26-5.89,3.41-7.23,5.98l-.58,1.38-.23.98c-.06.39-.1.79-.11,1.19l-.04,27.63h0c-.34,4.61,2.93,8.93,8.29,10.94,3.9,1.46,8.44,1.52,12.4.16,3.52-1.21,6.22-3.42,7.64-6.08l.46-1.01.02-.04c.02-.06.04-.11.06-.16.12-.37.22-.74.3-1.12.02-.07.03-.15.04-.22.06-.33.09-.66.11-.99,0-.08,0-.16,0-.24,0-.13,0-.25,0-.38l-.16-6.47.1.12v-.13c1.38,1.39,2.35,3.01,2.77,4.78l.18,1.37h.09s-.04.48-.04.48v.33c0,.08,0,.16,0,.24-.01.33-.05.67-.11.99-.01.07-.02.15-.04.22-.07.38-.17.75-.3,1.11l-.04.11-.15.58c-1.39,3.91-4.81,7.22-9.51,8.99-2.44.92-5.07,1.36-7.7,1.32-2.62-.04-5.24-.55-7.64-1.53-4.09-1.67-7.13-4.53-8.62-7.89l-.22-.59-.09-.17c-.5-1.09-.78-2.25-.81-3.45l-.04-28.66c.02-.8.15-1.59.38-2.34l.03-.09.08-.39c1.19-3.99,4.49-7.44,9.17-9.35,2.4-.98,5.02-1.49,7.64-1.53Z" style={{ fill: "#05c9f5", "fill-rule": "evenodd", stroke: "#05c9f5", "stroke-linejoin": "round", "stroke-width": "3.44px" }} />
		</svg>

	)
}
