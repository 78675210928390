import React from 'react'
import Logo from '../assets/icons/Logo'
import "./styles.css"
import { useCheckUserInfo } from '../hooks/checkUserInfo'
function LoadPage() {
	useCheckUserInfo()
	return (
		<div className='load-page'>
			<Logo />
		</div>
	)
}

export default LoadPage