import React, { useState } from 'react'

import './styles.css'
import { isEmpty, isFunction, isNumber } from 'lodash'
/**
 * 
 * @param {{
 * 	title: string
 * }  & React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>
 * } param0 
 * @param {React.ForwardedRef<HTMLInputElement>} ref
 * @returns 
 */ function TextInputFun({
	title = "",
	...props }, ref) {
	const id = props.id ?? title?.toLocaleLowerCase().replace(" ", "_")
	const hasValue = (!isEmpty(props.value)) || isNumber(props.value)
	const [isFocused, setIsFocused] = useState(false)
	function handleBlur(ev) {
		if (isFunction(props.onBlur)) {
			props.onBlur?.(ev)
		}
		//eslint-disable-next-line
		ev.target?.value == "" && setIsFocused(false)
	}
	function handleFocus(ev) {
		if (isFunction(props.onFocus)) {
			props.onFocus?.(ev)
		}
		setIsFocused(true)
	}
	return (
		<div
			className={"text-input-container " + (props.className ?? "")}
		>
			<label htmlFor={id} className={`label ${isFocused || hasValue ? "focused" : ""}`}>{title}</label>
			<input
				id={id} type="text"
				{...props}

				onFocus={handleFocus}
				onBlur={handleBlur}
				className="input"
				ref={ref}
			/>
		</div>
	)
}
const TextInput = React.forwardRef(TextInputFun);
export default TextInput