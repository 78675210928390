import React from 'react'
import UserProvider from './UserProvider'

function GeneralProvider({ children }) {
  return (

    <UserProvider>
      {children}
    </UserProvider>
  )
}

export default GeneralProvider