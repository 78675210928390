/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef, useState } from 'react'
import './styles.css'
import TextInput from '../TextInput/TextInput'
import Logo from '../assets/icons/Logo'
import Button from '../Button/Button'
import Tokens from '../models/Tokens'
import { useUserCtx, useUserCtxAndSubscription } from '../Providers/UserProvider'
import { useCheckUserInfo } from '../hooks/checkUserInfo'
import { isEmpty, isString } from 'lodash'
import { Link } from 'react-router-dom'
import ROUTES from '../constants/Routes'
import API_ERRORS from '../constants/API_ERRORS'
const DEFAULT_DATA = {
	usuario: "",
	dias: 0,
	meses: 0
}
export default function Codigo() {
	useCheckUserInfo()
	const [codigo, setCodigo] = useState("")
	const [data, setData] = useState(DEFAULT_DATA)
	const [user, , logout] = useUserCtxAndSubscription()
	const { getUserData, } = useUserCtx()
	/**
	 * @type {React.MutableRefObject<React.Ref<HTMLInputElement>>}
	 */
	const userRef = useRef(null)
	/**
	 * @type {React.MutableRefObject<React.Ref<HTMLInputElement>>}
	 */
	const diasRef = useRef(null)
	async function handleGenerar() {

		const correoRegex = /[a-zA-Z]*@[a-zA-Z]*\.[a-z]/;
		const telRegex = /^(\+{1}[0-9]{2,3})?[0-9]{10}$/
		const evaluate = data.usuario.trim().replace(/\s/g, "");
		if (!(correoRegex.test(evaluate) || telRegex.test(evaluate))) {
			userRef.current.setCustomValidity("Ingresa un correo o teléfono válido");
			userRef.current.reportValidity()
			return
		}
		data.usuario = evaluate
		userRef.current.setCustomValidity("")
		userRef.current.reportValidity()

		if (data.dias === 0 && data.meses === 0) {
			diasRef.current.setCustomValidity("Debes ingresar una duración de al menos un día");
			diasRef.current.reportValidity()
			return
		}
		diasRef.current.setCustomValidity("")
		diasRef.current.reportValidity()
		setCodigo("")
		const codigoRandom = await Tokens.generate(
			user.authToken,
			data
		);
		if (codigoRandom === API_ERRORS.NOT_FOUND) {
			return alert("No se ha hallado el usuario, por favor verifica la información")
		}
		if (codigoRandom === API_ERRORS.UNAUTHORIZED) {
			alert("Tu sesión ha expirado, por favor vuelve a iniciar sesión")
			logout()
			return
		}
		if (isString(codigoRandom) !== true) {
			return alert("Ocurrió un error, por favor inténtelo más tarde");
		}
		setCodigo(codigoRandom)
		await getUserData()
	}
	/**
	 * 
	 * @type {React.ChangeEventHandler<HTMLInputElement>} 
	 */
	function handleChangeUser(ev) {
		const { target: { value } } = ev
		setData(prev => ({ ...prev, usuario: value }))
	}
	/**
	 * 
	 * @type {React.ChangeEventHandler<HTMLInputElement>} 
	 */
	function handleChangeMeses(ev) {
		let { target: { value } } = ev
		value = isEmpty(value) ? null : parseInt(value)
		if (isNaN(value)) return

		setData(prev => ({ ...prev, meses: value }))
		console.log({ value });
	}
	/**
	 * 
	 * @type {React.ChangeEventHandler<HTMLInputElement>} 
	 */
	function handleChangeDias(ev) {
		let { target: { value } } = ev
		value = isEmpty(value) ? null : parseInt(value)
		if (isNaN(value)) return
		setData(prev => ({ ...prev, dias: value }))
	}
	return (
		<div
			className='codigo'
		>
			<div className="container">
				<header className="header">
					<Logo />
					<h1 className="title">Generar Código Promocional</h1>
				</header>
				<div className="inputs-container">
					<TextInput
						title='Correo/telefono'
						onChange={handleChangeUser}
						value={data.usuario}
						ref={userRef}
					/>
				</div>
				<div className="inputs-container">
					<TextInput
						title='Meses'
						onChange={handleChangeMeses}
						value={data.meses}
						className='number-input'
						pattern='[0-9]+'
					/>
					<TextInput
						title='Dias'
						onChange={handleChangeDias}
						value={data.dias}
						className='number-input'
						pattern='[0-9]+'
						ref={diasRef}
					/>
				</div>
				<div className="">
					<p
						className='codigo-container'
					>{codigo && codigo !== "" ? <>
						CÓDIGO: <span>{codigo}</span>
					</> : <></>
						}</p>
				</div>
				<div>
					<Button
						onClick={handleGenerar}
					>
						GENERAR
					</Button>
				</div>
				<div
					className='links'
				>
					<Link to={ROUTES.EXPIRACION}>EXPIRAR SUSCRIPCIÓN</Link>
					<a
						onClick={logout}
						href='/#'
					>CERRAR SESIÓN</a>
				</div>
			</div>
		</div>
	)
}
