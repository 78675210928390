import { useNavigate, useLocation } from 'react-router-dom'
import { useUserCtx } from '../Providers/UserProvider'
import { isEmpty } from 'lodash'
import ROUTES from '../constants/Routes'
import { useEffect } from 'react'

export function useCheckUserInfo() {
	const navigate = useNavigate()
	const location = useLocation();
	const { getUserData } = useUserCtx()
	/**
	 * 
	 * @param {import('../Providers/UserProvider').User} user 
	 * @returns 
	 */
	function checkUserData(user) {
		if (user.isLogged && !isEmpty(user.authToken)) {
			if (location.pathname === "/") {
				navigate(ROUTES.CODIGO)
			}
			return
		}
		if (location.pathname === ROUTES.LOGIN) return
		navigate(ROUTES.LOGIN)
	}
	useEffect(() => {
		getUserData().then(user => {
			console.log(user);
			if (user === false) {
				if (location.pathname === ROUTES.LOGIN) return
				return navigate(ROUTES.LOGIN)
			}
			checkUserData(user)

		}).catch(error => {
			console.error(error);
			if (location.pathname === ROUTES.LOGIN) return
			return navigate(ROUTES.LOGIN)

		})
		// eslint-disable-next-line
	}, [location])
}