
import {
  HashRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import './App.css'
import Login from "./Login/Login";
import Codigo from "./Codigo/Codigo";
import GeneralProvider from "./Providers/GeneralProvider";
import ROUTES from "./constants/Routes";
import LoadPage from "./LoadPage/LoadPage";
import Expiracion from "./Expiracion/Expiracion";
import EliminarCuentaVerificarUsuario from "./EliminarCuentaVerificarUsuario/EliminarCuentaVerificarUsuario";
import EliminarCuentaVerificarCodigo from "./EliminarCuentaVerificarCodigo/EliminarCuentaVerificarCodigo";
import EliminarCuenta from "./EliminarCuenta/EliminarCuenta";
import Support from "./Support/Support";

function App() {
  document.title = "Admin | Cooltura Bursátil"
  return (
    <GeneralProvider>
      <Router>
        <Routes>
          <Route
            path="/"
            Component={LoadPage}
          />
          <Route
            path={ROUTES.LOGIN}
            Component={Login}
          />
          <Route
            path={ROUTES.CODIGO}
            Component={Codigo}
          />
          <Route
            path={ROUTES.EXPIRACION}
            Component={Expiracion}
          />
          <Route
            path={ROUTES.ELIMINAR_CUENTA_VERIFICAR_USUARIO}
            Component={EliminarCuentaVerificarUsuario}
          />
          <Route
            path={ROUTES.ELIMINAR_CUENTA_VERIFICAR_CODIGO}
            Component={EliminarCuentaVerificarCodigo}
          />
          <Route
            path={ROUTES.ELIMINAR_CUENTA}
            Component={EliminarCuenta}
          />
          <Route
            path={ROUTES.SOPORTE}
            Component={Support}
          />
        </Routes>
      </Router>
    </GeneralProvider>
  );
}

export default App;
