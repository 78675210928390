import React from 'react'
import './styles.css'
import Logo from '../assets/icons/Logo'
import Button from '../Button/Button'
import API_ERRORS from '../constants/API_ERRORS'
import _ from "lodash"
import { ESTUDIATE_KEY } from '../constants/Keys'
import { Navigate, useNavigate } from 'react-router-dom'
import ROUTES from '../constants/Routes'
import Estudiantes from '../models/Estudiantes'

export default function EliminarCuenta() {
	const navigation = useNavigate()
	/**
	 * @type {{
	 * 	usuario: string,
	 * 	authToken: string
	 * } | null}
	 */
	const estudiate = JSON.parse(localStorage.getItem(ESTUDIATE_KEY) ?? "null")

	/**
	 * @type {React.MutableRefObject<React.Ref<HTMLInputElement>>}
	 */


	async function handleEliminarCuenta() {
		const deleted = await Estudiantes.delete(estudiate.authToken);
		if (deleted === API_ERRORS.NOT_FOUND) {
			return alert("No se ha hallado el usuario, por favor verifica la información")
		}
		if (deleted.ok !== true) {
			return alert("Ocurrió un error, por favor inténtelo más tarde");
		}
		alert("La cuenta ha sido eliminada")
		returnToHome()
	}
	function returnToHome() {
		localStorage.clear()
		navigation(ROUTES.ELIMINAR_CUENTA_VERIFICAR_USUARIO)
	}

	if (_.isNull(estudiate) || _.isEmpty(estudiate.usuario) || _.isEmpty(estudiate.authToken)) {
		return <Navigate to={ROUTES.ELIMINAR_CUENTA_VERIFICAR_USUARIO} />
	}
	return (
		<div
			className='expiracion'
		>
			<div className="container">
				<header className="header">
					<Logo />
					<h1 className="title">Cooltura Bursátil: Eliminar cuenta</h1>
				</header>
				<h2>¿ESTÁS SEGURO QUE DESEAS ELIMINAR TU CUENTA?</h2>
				<div>
					<Button
						onClick={handleEliminarCuenta}
					>
						SI
					</Button>
				</div>
				<div>
					<Button
						onClick={returnToHome}
					>
						NO
					</Button>
				</div>
			</div>
		</div>
	)
}
