/**
 * 
 * @param {string} usuario 
 * @param {React.MutableRefObject<React.Ref<HTMLInputElement>>} input
 * @returns {false | string}
 */
export function validateUserFormat(usuario, input) {
	const correoRegex = /[a-zA-Z]*@[a-zA-Z]*\.[a-z]/;
	const telRegex = /^(\+{1}[0-9]{2,3})?[0-9]{10}$/
	const evaluate = usuario.trim().replace(/\s/g, "");
	if (!(correoRegex.test(evaluate) || telRegex.test(evaluate))) {
		input.current.setCustomValidity("Ingresa un correo o teléfono válido");
		input.current.reportValidity()
		return false
	}
	input.current.setCustomValidity("")
	input.current.reportValidity()
	return evaluate
}
/**
 * 
 * @param {string} codigo 
 * @param {React.MutableRefObject<React.Ref<HTMLInputElement>>} input
 * @returns {false | string}
 */
export function validateCodeFormat(codigo, input) {
	const codigoRegex = /^[0-9]{6}$/;
	const evaluate = codigo.trim().replace(/\s/g, "");
	if (!codigoRegex.test(evaluate)) {
		input.current.setCustomValidity("Ingresa un código válido");
		input.current.reportValidity()
		return false
	}
	input.current.setCustomValidity("")
	input.current.reportValidity()
	return evaluate
}