import axios from "axios";
import API from "../constants/Api";

export default class Tokens {
	/** 
	 * @type { string}
	 */
	token;
	/** 
	 * @type { string}
	 */
	canjeado;
	/** 
	 * @type { string}
	 */
	fechaExpiracion;
	/** 
	 * @type { string}
	 */
	fechaCanjeado;
	/** 
	 * @type {number}
	 */
	suscripcionId;
	/** 
	 * @type {number}
	 */
	estudianteId;
	/** 
	 * @type {number}
	 */
	usuarioId;

	ERRORS = {
		SERVER_ERROR: 500,

	}
	/**
	 * 
	 * @param {string} accessToken 
	 * @param {{
	 * 	usuario: string,
	 * 	dias:string,
	 * 	meses: string
	 * }} data 
	 * @returns {Promise<string | number>}
	 */
	static async generate(
		accessToken,
		{
			usuario,
			dias,
			meses
		}) {
		const axiosResponse = await axios.post(API.TOKENS.generate, { usuario, dias, meses }, {
			headers: {
				"Authorization": accessToken
			}
		}).catch(error => {
			console.error(JSON.stringify(error, undefined, '\t'))
			return error.response
		});
		if (!axiosResponse.status.toString().startsWith("20")) {
			console.error(axiosResponse.data);
			return axiosResponse.status
		}

		return axiosResponse.data.data.token

	}
}