import React from 'react'
import { Link } from 'react-router-dom'
import './styles.css'
/**
 * 
 * @param {import('react-router-dom').LinkProps & React.RefAttributes<HTMLAnchorElement>} props 
 * @returns 
 */
export default function Button(props) {
	return (
		<Link
			{...props}
			className={props.className + " button-container"}
		>
			{props.children}
		</Link>
	)
}
