import React, { useState } from 'react'
import './styles.css'
import TextInput from '../TextInput/TextInput'
import Logo from '../assets/icons/Logo'
import Button from '../Button/Button'
import { isEmpty } from 'lodash'
import Usuarios from '../models/Usuarios'
import { useNavigate } from 'react-router-dom'
import ROUTES from '../constants/Routes'
import { useUserCtxAndSubscription } from '../Providers/UserProvider'
export default function Login() {
	const [data, setData] = useState({
		usuario: "",
		contrasenia: ""
	})
	const [, setUser] = useUserCtxAndSubscription()
	const navigate = useNavigate()
	async function handleClickAceptar() {
		try {
			if (isEmpty(data.contrasenia) || isEmpty(data.usuario)) {
				return alert("Por favor rellena todos los campos")
			}
			const loginResponse = await Usuarios.login(data.usuario, data.contrasenia)
			if (loginResponse === false || loginResponse === null) {
				throw new Error("Failed login")
			}
			if (loginResponse === Usuarios.ERRORS.NOT_VALID_CREDENTIALS) {
				return alert("Usuario o contraseña inválido")
			}
			const usuario = await Usuarios.info(loginResponse.accessToken)
			if (usuario === false) {
				throw new Error("Failed info getting")
			}
			usuario.isLogged = true
			usuario.authToken = loginResponse.accessToken
			setUser(usuario)
			return navigate(ROUTES.CODIGO)
		} catch (error) {
			console.error(error);
			alert("ocurrió un error, por favor intenta más tarde")
		}
	}
	return (
		<div
			className='login'
		>
			<div className="container">
				<header>
					<Logo />
					<h1 className="title">INICIAR SESIÓN</h1>
				</header>
				<div className="inputs-container">
					<TextInput
						title='Usuario'
						type='email'
						onChange={({ target: { value } }) => { setData(prev => ({ ...prev, usuario: value })) }}
					/>
					<TextInput
						title='Contraseña'
						type='password'
						onChange={({ target: { value } }) => { setData(prev => ({ ...prev, contrasenia: value })) }}
					/>
				</div>
				<div>
					<Button
						onClick={handleClickAceptar}
					>
						ACEPTAR
					</Button>
				</div>
			</div>
		</div>
	)
}
