import React from 'react'
import './styles.css'
import Logo from '../assets/icons/Logo'
import Button from '../Button/Button'

export default function Support() {
	return (
		<div
			className='expiracion'
		>
			<div className="container">
				<header className="header">
					<Logo />
					<h1 className="title">Cooltura Bursátil: Soporte</h1>
				</header>
				<h2>Si necesitas soporte con la aplicación puedes contactar a soporte via
					<a href="mailto:amelie.granados@ameliedevs.com">email</a>
				</h2>
			</div>
		</div>
	)
}
