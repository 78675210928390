import React, { useEffect, useReducer, useRef, useState } from 'react'
import './styles.css'
import TextInput from '../TextInput/TextInput'
import Logo from '../assets/icons/Logo'
import Button from '../Button/Button'
import API_ERRORS from '../constants/API_ERRORS'
import EmailTokens from '../models/EmailTokens'
import _ from "lodash"
import { validateCodeFormat, validateUserFormat } from '../helpers/validateFormats'
import { ESTUDIATE_KEY } from '../constants/Keys'
import { Navigate, useNavigate } from 'react-router-dom'
import ROUTES from '../constants/Routes'

export default function EliminarCuentaVerificarCodigo() {
	const codeRef = useRef(null)
	const [codigo, setCodigo] = useState("")
	const navigation = useNavigate()
	/**
	 * @type {{
	 * 	usuario: string,
	 * 	authToken: string
	 * } | null}
	 */
	const estudiate = JSON.parse(localStorage.getItem(ESTUDIATE_KEY) ?? "null")

	/**
	 * @type {React.MutableRefObject<React.Ref<HTMLInputElement>>}
	 */


	async function handleValidarCodigo() {
		const codigoEvaluated = validateCodeFormat(codigo, codeRef)
		const deleted = await EmailTokens.verify({
			usuario: estudiate.usuario,
			token: codigoEvaluated
		});
		if (deleted === API_ERRORS.NOT_FOUND) {
			return alert("No se ha hallado el usuario, por favor verifica la información")
		}
		if (deleted.ok !== true) {
			return alert("Ocurrió un error, por favor inténtelo más tarde");
		}
		localStorage.setItem(ESTUDIATE_KEY, JSON.stringify({ ...estudiate, authToken: deleted.authToken }))
		navigation(ROUTES.ELIMINAR_CUENTA)
	}

	/**
	 * 
	 * @type {React.ChangeEventHandler<HTMLInputElement>} 
	 */
	function handleChangeUser(ev) {
		const { target: { value } } = ev
		setCodigo(value)
	}
	if (_.isNull(estudiate) || _.isEmpty(estudiate.usuario)) {
		return <Navigate to={ROUTES.ELIMINAR_CUENTA_VERIFICAR_USUARIO} />
	}
	return (
		<div
			className='expiracion'
		>
			<div className="container">
				<header className="header">
					<Logo />
					<h1 className="title">Cooltura Bursátil: Eliminar cuenta</h1>
				</header>
				<h2>INGRESA EL CÓDIGO QUE LLEGÓ A TU CORREO</h2>
				<div className="inputs-container">
					<TextInput
						title='Codigo'
						type='number'
						onChange={handleChangeUser}
						value={codigo}
						ref={codeRef}
					/>
				</div>
				<div>
					<Button
						onClick={handleValidarCodigo}
					>
						VALIDAR
					</Button>
				</div>
			</div>
		</div>
	)
}
