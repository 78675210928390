import axios from "axios";
import Api from "../constants/Api";
import API_ERRORS from "../constants/API_ERRORS";

export default class EmailTokens {
	/**
	 * @type {string}
	 */
	token

	/**
	 * @type {boolean}
	 */
	usado

	/**
	 * @type {Date}
	 */
	fechaExpiracion

	/**
	 * @type {Date}
	 */
	fechaUso

	/**
	 * @type {number}
	 */
	estudianteId
	/**
	 * @typedef {import('./Estudiantes').default} Estudiantes
	 * @param {{
	 * 	usuario: string
	 * }} params 
	 * @returns {Promise<false|Estudiantes|null>}
	 */
	static async send(params) {
		try {
			const axiosResponse = await axios.post(Api.EMAIL_TOKENS.send, params).catch((error)=>{
				return error?.response
			})
			switch (parseInt(axiosResponse.status)) {
				case 200:
					return true
				case 404:
					return API_ERRORS.NOT_FOUND
				default:
					return false
			}
		} catch (error) {
			console.error("Error al enviar el correo: ", error, error.status)
			return false
		}
	}

	/**
	 * 
	 * @param {{
	 * 	usuario: string,
	 * 	token: string
	 * }} params 
	 * @returns {Promise<false|{authToken: string, ok: boolean}|null>}
	 */
	static async verify(params) {
		try {
			const axiosResponse = await axios.post(Api.EMAIL_TOKENS.verify, params).catch(error => {
				return error?.response
			})
			switch (axiosResponse.status) {
				case 200:
					return axiosResponse.data.data
				case 404:
					return API_ERRORS.NOT_FOUND
				default:
					return false
			}
		} catch (error) {
			console.error("Error al enviar el correo: ", error)
			return false
		}
	}
}