import axios from "axios";
import API from "../constants/Api";

export default class Suscripciones {
	/** 
	 * @type { Date}
	 */
	fechaExpiracion;
	/** 
	 * @type { Tokens}
	 */
	token;
	/** 
	 * @type { Pagos}
	 */
	pago;
	/** 
	 * @type {number}
	 */
	estudianteId;
	/** 
	 * @type {number}
	 */
	usuarioId;
	/**
	 * 
	 * @param {Suscripciones} params 
	 */
	constructor(params) {
		Object.entries(params).forEach(([key, val]) => {
			this[key] = val
		})
	}
	/**
	 * 
	 * @param {string} authToken 
	 * @param {string} usuario 
	 * @returns {Promise<boolean|number>}
	 */
	static async expire(authToken, usuario) {
		try {
			const axiosResponse = await axios.post(API.SUSCRIPCIONES.expire, {
				usuario
			}, {
				headers: {
					"Authorization": authToken
				}
			}).catch(error => {
				console.error(JSON.stringify({ reponse: error.response }, undefined, '\t'))
				return error.response
			});
			if (axiosResponse.status !== 200) {
				console.log(axiosResponse.status);
				return axiosResponse.status
			}
			return axiosResponse.data.data.ok
		} catch (error) {
			console.error("Error al hacer login: ", error)
			return false
		}
	}
}