import React, { createContext, useContext, useState, useRef, useEffect } from 'react'
import { USER_KEY } from '../constants/Keys';
import Usuarios from '../models/Usuarios';
const DEFAULT_USER = new Usuarios({
	isLogged: false,
	hasLoadedInfo: false,
	authToken: ""
})
/**
 * @typedef {Usuarios} User
 * @typedef {React.Dispatch< React.SetStateAction<User>>} UserSetState
 * @typedef {{
 * 	user: User,
 *  setUser: UserSetState
 * 	subscribe : (subscription: UserSetState)=>void,
 * 	unsubscribe : (subscription: UserSetState)=>void,
 * 	getUserData: ()=>Promise<false|User>
 * }} UserContext
 * 
 * @type {React.Context<UserContext>}
 */
const UserCtx = createContext(null)
export function useUserCtx() {
	return useContext(UserCtx)
}
/**
 * 
* @param {(user: User)=>void} onUpdateUser 
 * @returns {[User, UserSetState, ()=>void]}
 */
export function useUserCtxAndSubscription(
	onUpdateUser = () => { }
) {
	const { user: userCtx, subscribe, unsubscribe, setUser: setUserData } = useUserCtx()
	const [user, setUser] = useState(userCtx)
	/**
	 * 
	 * @param {Usuarios} userData 
	 */
	function subscription(userData) {
		console.debug(userData)
		onUpdateUser(userData);
		setUser(userData)
	}
	function logout() {
		setUserData(DEFAULT_USER)
	}
	useEffect(() => {
		subscribe(subscription);
		return () => {
			typeof unsubscribe === "function" && unsubscribe(subscription)
		}
		// eslint-disable-next-line
	}, [])
	return [user, setUserData, logout]
}
export default function UserProvider({ children }) {
	/**
	 * @type {[User, UserSetState]}
	 */
	const [user, setUser] = useState(DEFAULT_USER)
	/**
	 * @type {React.MutableRefObject<UserSetState[]>}
	 */
	const subscriptions = useRef([() => { }])
	/**
	 * 
	 * @param {UserSetState} subscription 
	 */
	function subscribe(subscription) {
		subscription(user)
		subscriptions.current.push(subscription);
	}
	/**
	 * 
	 * @param {UserSetState} subscription 
	 */
	function unsubscribe(subscription) {
		subscriptions.current = subscriptions.current.filter(sub => sub !== subscription)
	}
	/**
	 * 
	 * @param {User} newUser 
	 */
	function notifyChanges(newUser) {
		subscriptions.current.forEach(sub => sub(newUser))
	}
	/**
	 * 
	 * @param {((user: User)=>User )| User} newUserData 
	 */
	function setUserData(newUserData) {
		const newUser = typeof newUserData === "function" ? newUserData(user) : newUserData
		try {
			localStorage.setItem(USER_KEY, JSON.stringify(
				{
					...newUser,
					suscripciones: [],
					tokens: []
				}))
		} catch (error) {
			console.error(error)
		}
		notifyChanges(newUser);
		setUser(newUser)
	}
	/**
	 * 
	 * @returns {false | User}
	 */
	async function getUserData() {
		try {
			/**
			 * @type {Usuarios}
			 */
			const userLocalData = JSON.parse(localStorage.getItem(USER_KEY)) || DEFAULT_USER;
			let userData = DEFAULT_USER
			if (userLocalData.authToken) {
				userData = await Usuarios.info(userLocalData.authToken);
				userData.isLogged = true
				userData.authToken = userLocalData.authToken
			}
			if (userData === false){
				throw new Error("cannot get user info")
			}
			setUserData(userData)
			return userData
		} catch (error) {
			console.error(error)
			return false
		}
	}
	return (
		<UserCtx.Provider value={{ user, setUser: setUserData, subscribe, unsubscribe, getUserData }}>
			{children}
		</UserCtx.Provider>
	)
}
