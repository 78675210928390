import React, { useRef, useState } from 'react'
import './styles.css'
import TextInput from '../TextInput/TextInput'
import Logo from '../assets/icons/Logo'
import Button from '../Button/Button'
import { useUserCtx, useUserCtxAndSubscription } from '../Providers/UserProvider'
import { useCheckUserInfo } from '../hooks/checkUserInfo'
import Suscripciones from '../models/Suscripciones'
import API_ERRORS from '../constants/API_ERRORS'
import { Link } from 'react-router-dom'
import ROUTES from '../constants/Routes'
export default function Expiracion() {
	useCheckUserInfo()
	const [usuarioExpirar, setUsuarioExpirar] = useState("")
	const { getUserData } = useUserCtx()
	const [user,,logout] = useUserCtxAndSubscription()
	/**
	 * @type {React.MutableRefObject<React.Ref<HTMLInputElement>>}
	 */
	const userRef = useRef(null)
	async function handleGenerar() {
		try {
			const correoRegex = /[a-zA-Z]*@[a-zA-Z]*\.[a-z]/;
			const telRegex = /^(\+{1}[0-9]{2,3})?[0-9]{10}$/
			const evaluate = usuarioExpirar.trim().replace(/\s/g, "");
			if (!(correoRegex.test(evaluate) || telRegex.test(evaluate))) {
				userRef.current.setCustomValidity("Ingresa un correo o teléfono válido");
				userRef.current.reportValidity()
				return
			}
			userRef.current.setCustomValidity("")
			userRef.current.reportValidity()
			const deleted = await Suscripciones.expire(user.authToken, evaluate);
			if (deleted === API_ERRORS.NOT_FOUND) {
				return alert("No se ha hallado el usuario, por favor verifica la información")
			}
			if (deleted === API_ERRORS.NO_CONTENT) {
				return alert("El usuario no tiene una suscripción activa")
			}
			if (deleted !== true) {
				return alert("Ocurrió un error, por favor inténtelo más tarde");
			}


			alert(`La suscripción del usuario ${usuarioExpirar} ha sido expirada`)
		} catch (error) {
			console.error(error)
			alert("Lo sentimos, ocurrió un error, inténtalo más tarde")
		}
		try {
			await getUserData()
		} catch (error) {
			console.error(error)
		}
	}
	/**
	 * 
	 * @type {React.ChangeEventHandler<HTMLInputElement>} 
	 */
	function handleChangeUser(ev) {
		const { target: { value } } = ev
		setUsuarioExpirar(value)
	}
	return (
		<div
			className='expiracion'
		>
			<div className="container">
				<header className="header">
					<Logo />
					<h1 className="title">Expirar Suscripción de estudiante</h1>
				</header>
				<div className="inputs-container">
					<TextInput
						title='Correo/telefono'
						onChange={handleChangeUser}
						value={usuarioExpirar.usuario}
						ref={userRef}
					/>
				</div>
				<div>
					<Button
						onClick={handleGenerar}
					>
						EXPIRAR
					</Button>
				</div>

				<div
					className='links'
				>
					<Link to={ROUTES.CODIGO}>GENERAR CÓDIGO</Link>
					<a
						onClick={logout}
						href='/#'
					>CERRAR SESIÓN</a>
				</div>
			</div>
		</div>
	)
}
