export const BASE_URL = process.env.REACT_APP_API_BASE_URL ?? "https://api.cooltura-bursatil.ameliedevs.dev/api/v1"
export const BASE_URL_ESTUDIANTES = BASE_URL + "/estudiantes"
export const BASE_URL_USUARIOS = BASE_URL + "/usuarios"
export const BASE_URL_EMAIL_TOKENS = BASE_URL + "/emailTokens"
export const BASE_URL_MODULOS = BASE_URL + "/modulos"
export const BASE_URL_TOKENS = BASE_URL + "/tokens"
export const BASE_URL_SUSCRIPCIONES = BASE_URL + "/suscripciones"
const API ={
	ESTUDIANTES: {
		login: BASE_URL_ESTUDIANTES + "/login",
		register: BASE_URL_ESTUDIANTES + "/register",
		update: BASE_URL_ESTUDIANTES + "/update",
		delete: BASE_URL_ESTUDIANTES + "/delete",
		info: BASE_URL_ESTUDIANTES + "/info"
	},
	USUARIOS: {
		login: BASE_URL_USUARIOS + "/login",
		register: BASE_URL_USUARIOS + "/register",
		update: BASE_URL_USUARIOS + "/update",
		info: BASE_URL_USUARIOS + "/info"
	},
	EMAIL_TOKENS: {
		send: BASE_URL_EMAIL_TOKENS + "/send",
		verify: BASE_URL_EMAIL_TOKENS + "/verify"
	},
	MODULOS: {
		all: BASE_URL_MODULOS + "/all",
	},
	TOKENS: {
		generate: BASE_URL_TOKENS + "/generate",
	},
	SUSCRIPCIONES : {
		expire: BASE_URL_SUSCRIPCIONES + "/expire",
	}
}
export default API